import React, { useContext, useEffect, useState } from 'react';
import * as S from './FreeGiftSelectorV1.styles';
import FunnelContext from '../../context/FunnelContext';
import Select, { components } from 'react-select';

const FreeGiftSelector = props => {
  const { property_name, items } = props;

  const propertyName = property_name ?? 'free_gift_variant_id';

  const { extraObjects, setExtraObjects } = useContext(FunnelContext);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      let [defaultItem] = items.filter(item => item.default);
      if (defaultItem && !extraObjects[propertyName]) {
        setExtraObjects(
          Object.assign(extraObjects, {
            [propertyName]: defaultItem.value
          })
        );
        defaultItem = defaultItem?.extraObjects.map(item => {
          setExtraObjects(
            Object.assign(extraObjects, {
              [item.key]: item.value
            })
          );
          return item;
        });
      }
    }
    return setLoaded(true);
  }, [propertyName, items, extraObjects, setExtraObjects, loaded, setLoaded]);

  const handleSelectChange = item => {
    setExtraObjects(
      Object.assign(extraObjects, {
        [propertyName]: item.value
      })
    );
    return item?.extraObjects.map(i => {
      setExtraObjects(
        Object.assign(extraObjects, {
          [i.key]: i.value
        })
      );
      return i;
    });
  };
  const options = items?.map(item => {
    return item;
  });

  const { Option, SingleValue } = components;
  const IconOption = props => (
    <Option {...props}>
      <S.OptionImage src={props.data.icon} alt={props.data.label} />
      <S.OptionLabel>{props.data.label}</S.OptionLabel>
    </Option>
  );

  const SingleValue2 = props => (
    <SingleValue {...props}>
      <S.OptionImage src={props.data.icon} alt={props.data.label} />
      <S.OptionLabel>{props.data.label}</S.OptionLabel>
    </SingleValue>
  );

  return (
    <S.Container>
      {loaded && (
        <Select
          classNamePrefix="free-gift-select"
          options={options}
          onChange={handleSelectChange}
          inputProps={{ readOnly: true }}
          isSearchable={false}
          defaultValue={options.filter(
            option => option.value === extraObjects[propertyName]
          )}
          components={{ Option: IconOption, SingleValue: SingleValue2 }}
        />
      )}
    </S.Container>
  );
};

export default FreeGiftSelector;
